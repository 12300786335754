<template>
    <a-modal
            :visible="visible"
            :title="attributeName"
            :okText='this.currentValue ? "Cập nhật":"Thêm mới"'
            cancelText="Hủy"
            @cancel="() => { $emit('cancel') }"
            @ok="() => { $emit('create') }"
    >
        <a-form layout='vertical' :form="form">
            <a-form-item label="Chọn sản phẩm liên kết">
                <a-input type="hidden" v-decorator="['erpId',{ rules: [{ required: true, message: 'Vui lòng chọn sản phẩm liên kết!' }], }]"/>
                <a-select
                        labelInValue
                        :value="value"
                        placeholder="Nhập ID, Mã sản phẩm hoặc tên sản phẩm để tìm kiếm..."
                        style="width: 100%"
                        @inputKeydown="handleSearch"
                        @change="handleChange"
                        @search="handleSearchText"
                        :showSearch="true"
                >
                    <a-spin slot="notFoundContent" size="small" />
                    <a-select-option :value="d.id.toString()" v-for="d in products" :key="d.id">{{d.id}} - {{d.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="Haravan vID">
                <a-input placeholder="Nhập ID phiên bản của sản phẩm Haravan" v-decorator="['haravanVariantId',{ rules: [{ required: true, message: 'Vui lòng nhập ID phiên bản của sản phẩm Haravan!' }] }]"/>
            </a-form-item>
            <a-form-item :label='attributeName'>
                <a-input :placeholder="'Nhập ' + attributeName" v-decorator="['value',{ rules: [{ required: true, message: 'Vui lòng nhập ' + attributeName + '!' }] }]"/>
            </a-form-item>
            <a-form-item label="Mô tả">
                <a-textarea v-decorator="['description']" placeholder="" :rows="4"/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { removeUnicodeChar } from "../../util/StringUtils";

    export default {
        name: "VariantForm",
        props: ['visible', 'attributeName', 'currentValue'],
        data() {
            return {
                value: undefined,
                fetching: false,
                products: [],
                search: '',
                searchText: ''
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this);
            this.form.getFieldDecorator('erpId', { initialValue: undefined });
            this.form.getFieldDecorator('haravanVariantId', { initialValue: undefined });
            this.form.getFieldDecorator('value', { initialValue: undefined });
            this.form.getFieldDecorator('description', { initialValue: undefined });
        },
        methods: {
            handleSearchText(text) {
                this.searchText = text;
            },
            handleSearch(e) {
                // eslint-disable-next-line no-console
                if (e.code === 'Enter') {
                    if (this.searchText.length === 0) return [];
                    this.products = this.getErpProducts.filter( product => {
                        return product.id.toString().includes(this.searchText) ||
                            product.code.toUpperCase().includes(this.searchText.toUpperCase()) ||
                            removeUnicodeChar(product.name).toUpperCase().includes(removeUnicodeChar(this.searchText).toUpperCase())
                    });
                }
            },
            handleChange(value) {
                this.fetching = true;
                this.form.setFieldsValue({
                    erpId: value.key
                });

                Object.assign(this, {
                    value,
                    fetching: false,
                });
            }
        },
        computed: {
            ...mapGetters('erpProduct', [
                'getErpProducts'
            ]),
        },
        watch: {
            visible(value){
                if (value && !this.currentValue) {
                    this.value = undefined;
                    this.products = [];
                } else if (value && this.currentValue) {
                    this.form.setFieldsValue({
                        ...this.currentValue
                    });
                    this.products = this.getErpProducts.filter( product => product.id === this.currentValue.erpId);
                    this.value = {
                        key: this.currentValue.erpId,
                        label: this.currentValue.erpId + " - " + this.products[0].name
                    };
                } else {
                    this.form.resetFields();
                    this.value = undefined;
                    this.products = [];
                }
            }
        }
    }
</script>