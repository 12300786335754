<template>
    <a-drawer
            width="100%"
            style="overflow: auto"
            @close="onDrawerCancel"
            :visible="visible"
    >
        <div style="background-color: white;">
            <div class="drawer-header">
                <h2>Chọn hình ảnh</h2>
                <div class="drawer-action">
                    <a-button @click="handleSelectImage" type="primary">
                        Chọn {{ selectedImages.length }} hình ảnh
                    </a-button>
                </div>
            </div>
            <a-layout style="padding: 24px 0; padding-top: 0; background: #fff">
                <a-layout-content>
                    <h2>Upload hình ảnh</h2>
                    <div class="upload">
                        <a-upload
                                accept=".jpg,.jpeg,.png"
                                :action="config.uploadEndPoint + '/products/image/form'"
                                name="images"
                                listType="picture-card"
                                :fileList="fileList"
                                :headers="{'Authorization': `Bearer ${getCredentials.AccessToken}`}"
                                :multiple="true"
                                @preview="handlePreview"
                                @change="handleChange"
                        >
                            <div v-if="fileList.length === 0">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">Upload hình ảnh</div>
                            </div>
                        </a-upload>
                        <div v-if="fileList.length > 0">
                            <a-button @click="handleSaveImages" type="primary">Lưu hình ảnh</a-button>
                        </div>
                        <a-modal :centered="true" :width="700" :visible="previewVisible" :footer="null" @cancel="handleCancel">
                            <img alt="example" style="width: 100%" :src="previewImage" />
                        </a-modal>
                    </div>
                    <a-divider/>
                    <div class="gallery-container">
                        <a-checkbox-group ref="imageCheckbox" :defaultValue="defaultSelectedImages" @change="onChange">
                            <a-checkbox v-for="(image, index) in images" :key="index" :value="image.id">
                                <div class="image-thumb">
                                    <span :style="`display: inline-block; width: 120px; height: 120px; background-size: cover; background-image: url('${image.url}')`" />
                                    <div class="action">
                                        <a-icon @click="() => handlePreview(image)" style="margin: 5px" type="eye" />
                                        <a-popconfirm
                                                title="Bạn có chắc chắn muốn xóa?"
                                                @confirm="() => handleDeleteImage(image)"
                                                okText="Xóa"
                                                cancelText="Hủy"
                                        >
                                            <a-icon type="delete" />
                                        </a-popconfirm>
                                    </div>
                                </div>
                            </a-checkbox>
                        </a-checkbox-group>
                    </div>
                    <a-pagination :defaultPageSize="this.pageSize" @change="onPaginationChange" v-if="currentPage && images.length > 0" style="text-align: right; margin-top: 20px;" :current="currentPage.number + 1" :total="currentPage.totalElements" />
                </a-layout-content>
            </a-layout>
        </div>
    </a-drawer>
</template>

<script>
    import { RepositoryFactory } from "../../repository/RepositoryFactory";
    import config from "../../configs/ApplicationConfig.js";
    // eslint-disable-next-line no-unused-vars
    import { mapGetters } from "vuex";
    const imageRepository = RepositoryFactory.get('image');

    export default {
        name: "SelectMultiImageModal",
        props: ['visible', 'onCancel', 'onSelectImages', 'defaultSelectedImages'],
        data() {
            return {
                previewVisible: false,
                previewImage: '',
                fileList: [],
                images: [],
                config,
                currentPage: null,
                pageNumber: 0,
                pageSize: 30,
                loading: false,
                selectedImages: []
            }
        },
        watch: {
            async visible(value) {
                if (value) {
                    let  page = {
                        page: this.page,
                        size: this.pageSize,
                        sort: 'id,desc'
                    };
                    await this.getImageByPage({page});
                    this.selectedImages = this.images.filter( image => this.defaultSelectedImages.includes(image.id) );
                    if (this.selectedImages.length === 0) {
                        this.$refs.imageCheckbox.value = [];
                    }
                }
            }
        },
        methods: {
            handleCancel() {
                this.previewVisible = false;
            },
            handlePreview(file) {
                this.previewImage = file.url || file.thumbUrl;
                this.previewVisible = true;
            },
            handleChange({fileList}) {
                this.fileList = fileList;
            },
            handleSelectImage() {
                this.onSelectImages(this.selectedImages);
            },
            onSelectSingleImage(image) {
                this.onSelectImage(image);
            },
            handleSaveImages() {
                if (this.fileList.some(file => file.response === undefined)) {
                    this.openNotification("Upload hình ảnh bị lỗi vui lòng thử lại!");
                    this.fileList = [];
                }

                let images = this.fileList.map(file => {
                    return {
                        name: file.name,
                        url: file.response[0]
                    }
                });
                // eslint-disable-next-line no-console
                this.saveImage({images})
            },
            async handleDeleteImage(image) {
                // eslint-disable-next-line no-console
                try {
                    let {data} = await imageRepository.deleteImage({id: image.id});
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.images = this.images.filter(i => i.id !== image.id);
                } catch (e) {
                    this.openNotification(e.message);
                }

            },
            async onPaginationChange(pageNumber, pageSize) {
                let page = {
                    page: pageNumber - 1,
                    size: pageSize,
                    sort: 'id,desc'
                };
                await this.getImageByPage({page});
            },
            async saveImage({images}) {
                try {
                    let {data} = await imageRepository.addImages({images});
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.fileList = [];
                    this.images = [...data.data, ...this.images];
                } catch (e) {
                    this.openNotification(e.message);
                }
            },
            async getImageByPage({page}) {
                try {
                    let {data} = await imageRepository.getAllByPage({
                        page
                    });
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.currentPage = data.data;
                    this.images = data.data.content;
                } catch (e) {
                    this.openNotification(e.message);
                }
            },
            openNotification(message) {
                this.$notification.open({
                    message: message
                });
            },
            onDrawerCancel() {
                this.onCancel();
            },
            onChange(checkedValues) {
                this.selectedImages = this.images.filter( image => checkedValues.includes(image.id));
            }
        },
        computed: {
            ...mapGetters('authenticate', [
                'getCredentials'
            ]),
            dataImages() {
                return this.images.map( image => {
                    return {
                        src: image.url,
                        alt: image.name,
                        ...image
                    }
                });
            }
        }
    }
</script>

<style>
    .select-image {
        width: 120px;
        height: 120px;
    }
    .action {
        background-color: #eeeeee;
    }
    .image-thumb {
        cursor: pointer;
        display: inline-block;
        margin: 5px;
        padding: 5px;
        border: 1px solid #eee;
        border-radius: 5px;
    }
    .image-thumb-selected {
        cursor: pointer;
        display: inline-block;
        margin: 5px;
        padding: 5px;
        border: 1px solid #eeeeee;
        background-color: #007bff;
        border-radius: 5px;
    }
    .gallery-container {
    }
    .gallery-container .ant-checkbox-wrapper {
        position: relative;
    }
    .gallery-container .ant-checkbox-wrapper span.ant-checkbox {
        position: absolute !important;
        right: 5px !important;
    }
    .drawer-action {
        margin-right: 30px;
    }
    .drawer-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
    }
</style>