<template>
    <a-modal class="SpinModal" :bodyStyle="{height: 300}" :visible="visible" centered :footer="null" :closable="false">
        <div style="text-align: center">
            <a-spin size="large" />
        </div>
    </a-modal>
</template>

<script>
    export default {
        name: "Spinner",
        props: ['visible'],
    }
</script>

<style>
    .SpinModal .ant-modal-content {
        position: relative;
        background-color: transparent;
        border: 0;
         border-radius: 4px;
         background-clip: padding-box;
         -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
         box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
    }
</style>