<template>
    <vue-editor :disabled="disabled" :placeholder="placeholder" v-model="content" :editorToolbar="customToolbar" ></vue-editor>
</template>

<script>
    import { VueEditor } from "vue2-editor";

    let toolbar = [
        ["bold", "italic", "underline", "strike"],
        [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
        ],

        [{ header: 1 }, { header: 2 }],

        ["blockquote", "code-block"],

        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ color: [] }, { background: [] }],
        ["link", "image"]
    ];

    export default {
        components: {
            VueEditor
        },
        props: ["placeholder", "onChange", "disabled"],
        data() {
            return {
                content: "",
                customToolbar: toolbar
            };
        },
        methods: {
            setContent(content) {
                this.content = content;
            }
        },
        watch: {
            content: function (val) {
                this.onChange(val)
            }
        }
    };
</script>

<style>
    .editor__content ProseMirror {
        height: 300px;
        border: 1px solid #666666;
    }
</style>