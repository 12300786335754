<template>
    <div class="form-action" style="margin-top: 20px">
        <div class="form-action-header">
            <h2>Các phiên bản sản phẩm</h2>
        </div>
        <a-table v-if="visibleTable" :loading="tableLoading" :rowKey="record => record.id" :pagination="false" :columns="columns" :dataSource="data">
            <template v-for="col in ['value', 'erpProductId']" :slot="col" slot-scope="text, record">
                <div :key="col">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, col)"
                    />
                    <template v-else>{{text ? text: 'Chưa cập nhật'}}</template>
                </div>
            </template>
            <template slot="action" slot-scope="text, record">
                <div class='editable-row-operations'>
                    <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                      <a-button @click="() => edit(record.id)" icon="edit" />
                    </span>
                    <span v-if="record.editable" style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                        <a-popconfirm title='Bạn có chắc chắn?' @confirm="() => save(record)">
                            <a-button type="primary" style="display: inline-block; margin-bottom: 10px" icon="save" />
                        </a-popconfirm>
                        <a-popconfirm title='Bạn có chắc chắn hủy?' @confirm="() => cancel(record.id)">
                            <a-button  style="margin-left: 10px" icon="close" />
                        </a-popconfirm>
                    </span>
                    <span v-else style="margin-left: 10px; display: inline-block; margin-bottom: 10px;" />
                </div>
            </template>
            <template slot="default" slot-scope="text, record">
                <a-checkbox :disabled="true" :checked="text || record.value === 'Default'"/>
            </template>
            <template slot="active" slot-scope="text, record">
                <a-checkbox @click="() => handleChange(!record.isActive ,record.id, 'isActive')" :checked="text"/>
            </template>
        </a-table>
        <variant-form ref="form" @cancel="onCancelModal"  @create="onAddValue" :currentValue="currentValue" :attributeName="name" :visible="modalVisible" />
    </div>
</template>

<script>
    import VariantForm from "../components/forms/VariantForm";

    export default {
        name: "ProductVariant",
        props: ['variants'],
        components: {VariantForm },
        data() {
            return {
                tableLoading: false,
                data: this.variants,
                cacheData: [],
                modalVisible: false,
                currentValue: null,
                visibleTable: true,
                errorMessage: "",
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'position',
                        key: 'position',
                        width: '5%'
                    },
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        width: '10%'
                    },
                    {
                        title: 'ERP ID',
                        dataIndex: 'erpProductId',
                        key: 'erpProductId',
                        width: '15%',
                        scopedSlots: { customRender: 'erpProductId' }
                    },
                    {
                        title: 'Tên',
                        dataIndex: 'value',
                        key: 'value',
                        width: '20%',
                        scopedSlots: { customRender: 'value' }
                    },
                    {
                        title: 'Giá',
                        dataIndex: 'price',
                        key: 'price',
                        width: '10%'
                    },
                    {
                        title: 'Mặc định',
                        dataIndex: 'isDefault',
                        key: 'isDefault',
                        width: '5%',
                        scopedSlots: { customRender: 'default' }
                    },
                    {
                        title: 'Active',
                        dataIndex: 'isActive',
                        key: 'isActive',
                        width: '5%',
                        scopedSlots: { customRender: 'active' }
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        width: '20%',
                        scopedSlots: { customRender: 'action' }
                    },
                ],
            }
        },
        methods: {
            handleChange (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                }
            },
            edit(id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target.editable = true;
                    this.data = newData;
                }
            },
            save(record) {
                const newData = [...this.data];
                const target = newData.filter(item => record.id === item.id)[0];
                if (target) {
                    delete target.editable;
                    this.data = newData;
                    this.cacheData = newData.map(item => ({ ...item }))
                }
            },
            cancel (id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => id === item.id)[0])
                    delete target.editable;
                    this.data = newData
                }
            },
            onEditValue(value) {
                this.currentValue = value;
                this.modalVisible = true;
            },
            showModal() {
                this.modalVisible = true;
            },
            onCancelModal() {
                this.modalVisible = false;
                this.currentValue = null;
            },
            onAddValue() {
                let form = this.$refs.form.form;
                let that = this;
                form.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    if (this.currentValue) {
                        that.data[this.currentValue.stt] = {...this.currentValue, ...values};
                    } else {
                        values.active = true;
                        values.deleted = false;
                        that.data.push(values);
                    }
                    that.modalVisible = false;
                    this.currentValue = null;
                });

                form.resetFields();
                this.data = JSON.parse(JSON.stringify(this.data));
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            }
        },
        watch: {
            data: function (data) {
                this.$emit('change', data);
            },
            variants: function (data) {
                this.data = data.map(item => Object.assign({ editable: false }, item));
                this.cacheData = data.map(item => Object.assign({ editable: false }, item));
            }
        }
    }
</script>

<style scoped>
    .form-action-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
</style>