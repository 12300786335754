<template>
    <a-layout-content class="AddNewProduct">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>Cập nhật thông tin sản phẩm</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="product-form" :style="{ margin: '15px' }">
            <a-form :form="formAddNewProduct" @submit="handleSubmit">
                <a-affix :offsetTop="10" style="text-align: right; margin-right: 10px;">
                    <a-form-item>
                        <a-button @click="cancel" icon="close-circle" style="margin-right: 15px">
                            Huỷ
                        </a-button>
                        <a-button icon="save" type="primary" html-type="submit">
                            Lưu sản phẩm
                        </a-button>
                    </a-form-item>
                </a-affix>
                <a-layout style="padding: 24px 0;">
                    <a-layout-content>
                        <div style="background-color: white; margin: 0 24px; padding: 20px 30px">
                            <h2>Thông tin sản phẩm</h2>
                            <a-divider />
                            <a-form-item>
                                <a-input type="hidden" v-decorator="['id']"/>
                            </a-form-item>
                            <a-form-item label="Haravan ID" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input :disabled="true" type="number" placeholder="VD: 123412 ..." v-decorator="['haravanId']"/>
                            </a-form-item>
                            <a-form-item label="Tên sản phẩm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input :disabled="disableEdit" placeholder="VD: Sáp Glanzen Clay 60g ..." v-decorator="['name', { rules: [{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }] }]"/>
                            </a-form-item>

                            <!-- Select Product featuredImage -->
                            <a-form-item label="Hình ảnh đại diện" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input type="hidden" v-decorator="['featuredImage', { rules: [{ required: true, message: 'Vui chọn hình ảnh đại diện cho sản phẩm!' }] }]"/>
                                <div v-if="!avatarSelected" @click="handleShowAvatar" class="imageSelect">
                                    <a-icon style="font-size: 20px; margin-bottom: 10px;" type="plus-circle" />
                                    <span style="text-align: center; display: block; font-weight: 500; line-height: 18px">Chọn hình đại diện</span>
                                </div>
                                <div  @click="handleShowAvatar" v-else-if="!disableEdit" style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;">
                                    <span  :style="`display: inline-block; width: 100px; height: 100px; background-size: cover; background-image: url('${avatarSelected.url}')`" />
                                </div>
                                <div v-else style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;">
                                    <span  :style="`display: inline-block; width: 100px; height: 100px; background-size: cover; background-image: url('${avatarSelected.url}')`" />
                                </div>
                            </a-form-item>

                            <!-- Select Product Images -->
                            <a-form-item label="Hình ảnh sản phẩm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input type="hidden" v-decorator="['images', { rules: [{ required: true, message: 'Vui chọn hình ảnh cho sản phẩm!' }] }]"/>
                                <div class="product-images">
                                    <div v-for="image in selectedImages" :key="image.id" style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;">
                                        <span  :style="`display: inline-block; width: 100px; height: 100px; background-size: cover; background-image: url('${image.url}')`" />
                                    </div>
                                    <div v-if="!disableEdit" @click="handleShowSelectImages" class="imageSelect">
                                        <a-icon style="font-size: 20px; margin-bottom: 10px;" type="plus-circle" />
                                        <span style="text-align: center; display: block; font-weight: 500; line-height: 18px">Hình ảnh sản phẩm</span>
                                    </div>
                                </div>
                            </a-form-item>
                            <a-form-item label="Mô tả ngắn" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input type="hidden" v-decorator="['shortDescription', { rules: [{ required: true, message: 'Vui nhập mô tả ngắn cho sản phẩm!' }] } ]" />
                                <editor ref="shortDescription" :disabled="disableEdit" :onChange="onShortDescriptionChange" placeholder="Nhập mô tả ngắn cho sản phẩm, khoảng 100 từ..."/>
                            </a-form-item>
                            <a-form-item label="Mô tả chi tiết" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input type="hidden" v-decorator="['fullDescription', { rules: [{ required: true, message: 'Vui nhập mô tả chi tiết cho sản phẩm!' }] } ]" />
                                <editor class="full-description" ref="fullDescription" :disabled="disableEdit" :onChange="onDescriptionChange" placeholder="Nhập mô tả chi tiết cho sản phẩm..."/>
                            </a-form-item>
                            <a-form-item label="Hướng dẫn sử dụng" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                                <a-input type="hidden" v-decorator="['manual', { rules: [{ required: true, message: 'Vui nhập hướng dẫn sử dụng cho sản phẩm!' }] } ]" />
                                <editor style="max-height: 600px" ref="manual" :disabled="disableEdit" :onChange="onManualChange" placeholder="Nhập hướng dẫn sử dụng cho sản phẩm..."/>
                            </a-form-item>
                        </div>
                        <div style="margin: 0 24px;">
                            <product-variant @change="updateVariants" :variants="productVariants" />
                        </div>
                    </a-layout-content>
                    <a-layout-sider breackpoint="xl" width="30%" style="background: #f0f2f5; border-right: 1px solid #eeeeee; padding-right: 10px">
                        <div class="form-action">
                            <h2>Thông tin bổ sung</h2>
                            <a-divider />
                            <a-form-item label="Xuất sứ">
                                <a-input :disabled="disableEdit" placeholder="VD: Việt Nam, Trung Quốc.." v-decorator="['country', { rules: [{ required: true, message: 'Vui lòng nhập xuất sứ của sản phẩm!' }] }]"/>
                            </a-form-item>
                            <a-form-item label="Thương hiệu">
                                <a-input :disabled="disableEdit" placeholder="VD: Glanzen Clay, TIKI RK.." v-decorator="['brand', { rules: [{ required: true, message: 'Vui lòng nhập thương hiệu của sản phẩm!' }] }]"/>
                            </a-form-item>
                        </div>
                        <div class="form-action" style="margin-top: 20px">
                            <h2>Danh mục sản phẩm *</h2>
                            <a-form-item>
                                <a-input type="hidden" v-decorator="['category', { rules: [{ required: true, message: 'Vui lòng chọn danh mục của sản phẩm!' }] }]"/>
                                <a-select ref="selectCategory" v-model="categorySelectedId" :disabled="disableEdit" placeholder="Chọn danh mục sản phẩm..">
                                    <a-select-option v-for="category in categories" :value="category.id" :key="category.id">
                                        <div style="display: flex; flex-direction: row; align-items: center">
                                            <a-avatar shape="square" size="small" :src="category.featuredImage.url" />
                                            <span style="margin-left: 10px; font-weight: bold">{{category.name}}</span>
                                        </div>
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>

                        <div class="form-action" style="margin-top: 20px">
                            <h2>Danh sách thẻ</h2>
                            <a-form-item>
                                <a-input type="hidden" v-decorator="['tags']"/>
                                <a-select ref="selectTags" v-model="tagsSelect" :disabled="disableEdit" mode="multiple" placeholder="Chọn danh sách thẻ...">
                                    <a-select-option v-for="tag in tags" :value="tag.id" :key="tag.id">
                                        {{tag.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                    </a-layout-sider>
                </a-layout>
            </a-form>
        </div>
        <select-single-image-modal :onSelectImage="onSelectAvatar" :onCancel="onCancelAvatar" :visible="selectAvatarVisible" />
        <select-multi-image-modal :defaultSelectedImages="defaultSelectedImages" :onSelectImages="onSelectImages" :onCancel="onCancelSelectImages" :visible="selectedImagesVisible"/>
        <Spinner :visible="disableEdit" />
    </a-layout-content>
</template>

<script>
    import './_Product.scss'
    import Editor from '../../components/Editor'
    import SelectMultiImageModal from "../../components/modal/SelectMultiImageModal";
    import SelectSingleImageModal from "../../components/modal/SelectSingleImageModal"
    import Spinner from "../../components/modal/Spinner";
    import ProductVariant from "../../components/ProductVariant";
    import {RepositoryFactory} from "../../repository/RepositoryFactory";
    const categoryRepository = RepositoryFactory.get("category");
    const tagRepository = RepositoryFactory.get("tag");
    const productRepository = RepositoryFactory.get("product");

    export default {
        name: "AddNewProduct",
        components: {
            Editor, SelectMultiImageModal, SelectSingleImageModal, Spinner, ProductVariant
        },
        data() {
            return {
                formLayout: 'horizontal',
                formAddNewProduct: this.$form.createForm(this),
                loading: false,

                // featuredImage select
                avatarUrl: "",
                selectAvatarVisible: false,
                avatarSelected: null,

                // Product images select
                selectedImagesVisible: false,
                selectedImages: [],
                defaultSelectedImages: [],
                variants: [],

                // Erp product sync
                erpProduct: null,
                product: {
                    id: undefined,
                    name: "",
                    price: 0,
                    country: "",
                    brand: "",
                    shortDescription: "",
                    fullDescription: "",
                    manual: "",
                    category: null,
                    featuredImage: "",
                    images: [],
                    tags: [],
                    variants: []
                },

                categories: [],
                tags: [],
                categorySelectedId: undefined,
                tagsSelect: [],
                disableEdit: false,
                isUpdate: false
            };
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault();
                this.formAddNewProduct.validateFields( async (err, product) => {
                    if (err) {
                        this.$notification["error"]({
                            message: "Thông tin sản phẩm không hợp lệ",
                            description: "Thông tin sản phẩm có thể chưa đúng định dạng hoặc còn thiếu, bạn vui lòng kiểm tra lại toàn bộ trước khi cập nhật sản phẩm!"
                        });

                        return;
                    }
                    this.disableEdit = true;
                    try {
                        product.featuredImage = JSON.parse(product.featuredImage);
                        product.images = JSON.parse(product.images);
                        product.tags = JSON.parse(product.tags);
                        product.category = JSON.parse(product.category);
                        product.variants = this.variants.map( item => {
                            delete item.stt;
                            delete item.editable;
                            return item;
                        });

                        let dataResponse = await productRepository.updateProductById({id: product.id, product});
                        let { data } = dataResponse;
                        if (data.meta.success) {
                            this.success();
                        }

                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log(e)
                    } finally {
                        this.disableEdit = false;
                    }
                });
            },
            handleShowAvatar() {
                this.selectAvatarVisible = true;
            },
            onCancelAvatar() {
                this.selectAvatarVisible = false;
            },
            onSelectAvatar(image) {
                this.formAddNewProduct.setFieldsValue({
                    featuredImage: JSON.stringify(image)
                });
                this.avatarSelected = image;
                this.selectAvatarVisible = false;
            },

            handleShowSelectImages() {
                this.selectedImagesVisible = true;
            },

            onCancelSelectImages() {
                this.selectedImagesVisible = false;
            },

            onSelectImages(images) {
                this.formAddNewProduct.setFieldsValue({
                    images: JSON.stringify(images)
                });
                this.selectedImages = images;
                this.selectedImagesVisible = false;
            },

            removeImageSelected() {
                this.formAddNewProduct.resetFields();
                this.formAddNewProduct.setFieldsValue({
                    featuredImage: null
                });
                this.avatarSelected = null;
            },

            onModalCancel() {
                this.$emit('cancel');
                this.formAddNewProduct.setFieldsValue({
                    featuredImage: null
                });
                this.avatarSelected = null;
            },

            async fetchCategories() {
                try {
                    let { data } = await categoryRepository.getAll();
                    if (data.meta.success) {
                        this.categories = data.data;
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e.message)
                }
            },

            async fetchTags() {
                try {
                    let { data } = await tagRepository.getAll();
                    if (data.meta.success) {
                        this.tags = data.data;
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e.message)
                }
            },

            async fetchProduct(id) {
                try {
                    let { data } = await productRepository.getProductById({id});
                    if (!data.meta.success) throw new Error("Không thể lấy thông tin sản phẩm!");
                    if (data.data) {
                        this.isUpdate = true;
                        this.product = {...this.product, ...data.data};
                    }
                } catch (e) {
                    this.product = {...this.product, ...this.$route.params.product};
                }
            },

            // Editor handle
            onShortDescriptionChange(value) {
                this.formAddNewProduct.setFieldsValue({
                    shortDescription: value
                });
            },
            onDescriptionChange(value) {
                this.formAddNewProduct.setFieldsValue({
                    fullDescription: value
                });
            },
            onManualChange(value) {
                this.formAddNewProduct.setFieldsValue({
                    manual: value
                });
            },
            onCategorySelect(id) {
                let cat = this.categories.find( category => category.id === id );
                this.formAddNewProduct.setFieldsValue({
                    category: JSON.stringify(cat)
                });
            },
            onTagsSelect(tags) {
                let tagsSelected = this.tags.filter( tag => tags.includes(tag.id) );

                this.formAddNewProduct.setFieldsValue({
                    tags: JSON.stringify(tagsSelected)
                });
            },

            updateVariants(values) {
                this.variants = values;
            },

            fillProductToForm(product) {
                this.formAddNewProduct.setFieldsValue({
                    ...this.product,
                    featuredImage: JSON.stringify(product.featuredImage),
                    images: JSON.stringify(product.images),
                    tags: JSON.stringify(product.tags),
                    category: JSON.stringify(product.category),
                });

                if (product.featuredImage) {
                    this.avatarSelected = product.featuredImage;
                    this.avatarUrl = product.featuredImage.url;
                }

                if (product.images) {
                    this.selectedImages = product.images;
                    this.defaultSelectedImages = product.images.map( image => image.id );
                }

                if (product.category) {
                    this.categorySelectedId = product.category.id;
                }
                if (product.tags) {
                    let tagIds = product.tags.map( tag => tag.id );
                    if (tagIds.length > 0) this.tagsSelect = tagIds;
                }

                this.$refs.shortDescription.setContent(this.product.shortDescription);
                this.$refs.fullDescription.setContent(this.product.fullDescription);
                this.$refs.manual.setContent(this.product.manual);
            },
            cancel() {
                if (this.product.category) {
                    this.$router.push({ path: '/products/category/' + this.product.category.id});
                } else {
                    this.$router.push({ path: '/products'});
                }
            },
            success() {
                this.$success({
                    title: 'Cập nhật sản phẩm thành công!',
                    okText: "Tiếp tục",
                    centered: true,
                    onOk: () => {
                        if (this.product.category) {
                            this.$router.push({ path: '/products/category/' + this.product.category.id});
                        } else {
                            this.$router.push({ path: '/products'});
                        }
                    }
                });
            },
        },
        watch: {
            erpProduct: function (val) {
                this.formAddNewProduct.setFieldsValue({
                    ...val
                });
            },
            product: function (product) {
                this.fillProductToForm(product);
            },
            categorySelectedId: function (id) {
                this.onCategorySelect(id);
            },
            tagsSelect: function (tags) {
                this.onTagsSelect(tags);
            }
        },
        computed: {
            productVariants() {
                return this.product.variants;
            }
        },
        async created() {
            try {
                this.disableEdit = true;
                if (this.$route.params.product) {
                    await this.fetchTags();
                    await this.fetchCategories();
                    await this.fetchProduct(this.$route.params.product.id);
                } else {
                    this.$router.push({ path: '/products' });
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e.message)
            } finally {
                this.disableEdit = false;
            }
        }
    }
</script>

<style scoped>
    .imageSelect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        padding: 20px;
        border-radius: 10px;
        border: 1px dotted rgba(0, 0, 0, 0.2);
        cursor: pointer;
        margin-left: 20px;
    }
    .product-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .form-action-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
</style>